<template>
  <k-layout :navProps="{ hasBorder: false }">
    <project-list :show-aside="true" :sticky-top="44">
      <template>
        <div class="tw-relative tw-overflow-hidden tw-rounded-2xl tw-bg-gray-600 tw-rounded-sm">
          <img
            class="tw-absolute tw-w-full tw-inset-0 tw-z-0 tw-h-full tw-object-cover tw-object-center"
            src="./../assets/referral-teaser-bg-new.svg"
            alt=""
          />
          <div class="tw-relative tw-flex tw-flex-col tw-pt-10 tw-pl-10 tw-pr-10">
            <p class="tw-text-white tw-text-2xl tw-leading-tight tw-font-bold">
              {{ $t('project.title') }}
            </p>
            <p class="tw-text-white tw-mt-2 tw-leading-normal">
              {{ $t('project.description') }}
            </p>
            <k-link :to="{ name: 'referral' }" class="tw-no-underline tw-mt-8 tw-inline-block">
              <k-button>
                {{ $t('project.actions.learn_more') }}
              </k-button>
            </k-link>
          </div>
          <img class="tw-relative tw-w-full tw-mt-10 tw--mb-2" src="./../assets/referral-teaser-new.svg" alt="" />
        </div>
      </template>
    </project-list>
  </k-layout>
</template>

<script>
import Sticky from 'vue-sticky'

import ProjectList from '@web/projects/components/project-list'
import KButton from '@web/components/core/k-button'

export default {
  directives: {
    Sticky
  },
  components: {
    KButton,
    ProjectList
  }
}
</script>
